import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "@components/pages/privacy/Content.js"
import { Footer, Hero } from "@components"

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO
        title="Dynobase - Privacy Policy"
        description="Learn more about Dynobase's privacy policy."
        canonical="https://dynobase.dev/privacy/"
      />
      <Hero title="Dynobase Privacy Policy" />
      <Content />
      <Footer />
    </Layout>
  )
}

export default PrivacyPage
